import { useEffect, useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { Configuration } from '../types/configuration';
import { config as defaultConfig } from '../../config';

/**
 * Get the roles the user has in a given environment
 *
 * @param config Configuration for the environment to check
 * @returns List of assigned roles
 */
export const useRoles = <T extends string>(config: Configuration = defaultConfig) => {
  const { instance } = useMsal();
  const account = useAccount() || undefined;
  const [roles, setRoles] = useState<T[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  useEffect(() => {
    const request: SilentRequest = {
      account,
      scopes: [config.SCOPE],
    };
    instance.acquireTokenSilent(request)
      .then((tokenResponse) => {
        try {
          const token = tokenResponse.accessToken;
          const [, claims] = token.split('.');
          const claimsDecoded = window.atob(claims);
          const claimsObject: { roles: T[] } = JSON.parse(claimsDecoded);
          setRoles(claimsObject.roles);
          setIsAuthenticated(true);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          // TODO: Maybe some loggging here?
        }
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(request);
        }
        return null;
      });
  }, [instance, account, config.SCOPE]);
  return { roles, isAuthenticated };
};
