import {
  Badge,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export const FilterIcon = ({
  filterActive = false,
}: {
  filterActive?: boolean
}) => (
  <Badge
    color="info"
    variant="dot"
    invisible={!filterActive}
    sx={{
      '& .MuiBadge-badge': {
        right: 2,
        top: 6,
      },
    }}
  >
    <FilterListIcon />
  </Badge>
);
