import {
  FC,
  HTMLAttributes,
  ReactNode,
  useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Backdrop,
  Skeleton,
  TextField,
} from '@mui/material';
import { SelectItem } from '../../types/util/selectItem';
import './style.scss';

export const MultiSearchSelect: FC<{
  label?: string,
  value?: SelectItem[],
  onChange?: (newValue: SelectItem[]) => void
  size?: 'medium' | 'small',
  children?: SelectItem[],
  required?: boolean,
  disabled?: boolean,
  loading?: boolean,
  disableBackdrop?: boolean,
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectItem,
    state: AutocompleteRenderOptionState
  ) => ReactNode,
  renderTags?: (items: SelectItem[]) => ReactNode,
  }> = ({
    label = 'Søk etter prosjekt',
    value = [],
    onChange = () => null,
    size = 'medium',
    children = [],
    required = false,
    disabled = false,
    loading = false,
    disableBackdrop = false,
    renderOption,
    renderTags,
  }) => {
    const [isPickerOpen, setPickerOpen] = useState<boolean>(false);

    if (loading) {
      return (
        <Skeleton
          width="100%"
          height={`${size === 'medium' ? 56 : 40}px`}
          variant="rectangular"
        />
      );
    }

    return (
      <>
        {!disableBackdrop && (
          <Backdrop
            sx={{ zIndex: 5 }}
            invisible
            onClick={() => setPickerOpen(false)}
            open={isPickerOpen}
          />
        )}
        <Autocomplete
          className={`multi-search-select-component size-${size}`}
          multiple
          disableCloseOnSelect
          limitTags={1}
          value={value}
          disabled={disabled}
          isOptionEqualToValue={(a, b) => `${a.id}` === `${b.id}`}
          clearOnBlur
          clearOnEscape
          openOnFocus
          onChange={(e, newValue) => {
            onChange(newValue);
          }}
          options={children}
          fullWidth
          getOptionLabel={(option) => option?.label}
          open={isPickerOpen}
          onOpen={() => setPickerOpen(true)}
          onClose={() => setPickerOpen(false)}
          renderOption={!renderOption ? undefined : (props, option, state) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props}>{renderOption(props, option, state)}</li>
          )}
          renderTags={(items) => {
            if (renderTags !== undefined) return renderTags(items);
            return (
              !isPickerOpen && (
                <span className="filter-display-line">
                  <span className="one-line-ellipse flexchild">
                    {`${items[0].label}`}
                  </span>
                  <span>
                    {`${items.length > 1 ? (` + ${items.length - 1}`) : ''}`}
                  </span>
                </span>
              )
            );
          }}
          renderInput={(params) => (
            <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={label}
              helperText={required && value.length <= 0 ? 'Velg et prosjekt' : null}
              error={required && value.length <= 0}
              size={size}
            />
          )}
          size={size}
        />
      </>
    );
  };
