import { useLocation } from 'react-router-dom';
import { SelectItem } from '../types/util/selectItem';

export const urlCombine = (a: string, b: string) => `${a.replace(/\/$/, '')}/${b.replace(/^\//, '')}`;

export const leftPad = (s: string, n: number, c: string = '0') => (
  `${c.repeat(n)}${s}`.slice(-n)
);

export const labelSort = (a: SelectItem, b: SelectItem) => (
  a.label.localeCompare(b.label, 'nb')
);

export const useQuery = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  return Object.fromEntries(query);
};

export const stopPropagation = (e: {stopPropagation: () => void}) => {
  e.stopPropagation();
};

export const onlyQuarters = (
  timeValue: number,
  clockType: 'hours' |'minutes' | 'seconds',
) => clockType === 'minutes' && (timeValue / 15) !== Math.round(timeValue / 15);

/** Converts movement rules into eventInput fields */
export const allowedEventMovement = (
  /** If false disable all editing of event */
  allowEdit: boolean,
  /** Can drag event from the start */
  allowStart: boolean,
  /** Can drag event from the end */
  allowEnd: boolean,
  /** Can move event between times on timeline */
  allowHorizontal: boolean,
  /** Can move event between resources */
  allowVertical: boolean,
) => {
  // uses CSS from shared/global.scss to disable start and end

  if (!allowEdit) return { editable: false };

  return {
    editable: false,
    durationEditable: true,
    className: `${allowStart ? '' : 'not-resizable-start'} ${allowEnd ? '' : 'not-resizable-end'}`,
    startEditable: allowVertical,
    resourceEditable: allowHorizontal,
  };
};
