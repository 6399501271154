import { ConfigurationSettings, createConfig } from '.';
import { getEnvironmentName } from '../../localEnvironment';
import { EnvironmentName } from '../types/enums/environmentName';

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3002',
  BACKEND_URL: 'https://localhost:44307/',
  CLIENT_ID: 'ad9c6d88-b311-415d-9320-308cdfdb2e02',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://mango-pebble-03468b803.1.azurestaticapps.net',
  BACKEND_URL: 'https://maskinplanlegger-backend-utv.azurewebsites.net',
  CLIENT_ID: 'ad9c6d88-b311-415d-9320-308cdfdb2e02',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://maskin-test.tsmaskin.no',
  BACKEND_URL: 'https://maskinplanlegger-test-backend.azurewebsites.net/',
  CLIENT_ID: 'fb59de12-4333-4346-a8c7-bf1718b0770b',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://maskin.tsmaskin.no',
  BACKEND_URL: 'https://maskinplanlegger-prod-backend.azurewebsites.net/',
  CLIENT_ID: '3a1116fc-a776-48a4-9f8f-ddfb071572c6',
};

export const getEnvironment = (
  envName: EnvironmentName,
): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
