/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addHours,
  formatISO,
  isBefore,
  startOfDay,
} from 'date-fns';
import { useSelector } from '../../hooks/redux';
import { EquipmentCategory } from '../../types/equipmentCategory';
import { DateTime } from '../../types/util/dateTime';
import { SelectItem } from '../../types/util/selectItem';

const stateName = 'rentalOrderState';

interface InitialState {
    activeExtraEquipment: string[],
    from: DateTime,
    to: DateTime,
    project: SelectItem|undefined,
    mainCat: SelectItem|undefined,
    subCat: SelectItem|undefined,
    worksite: string | undefined,
    comment: string|undefined,
    selectedEquipment: SelectItem|undefined,
    orderedBy: number|undefined,
}

const initialState: InitialState = {
  activeExtraEquipment: [],
  from: formatISO(addHours(startOfDay(new Date()), 7)),
  to: formatISO(addHours(startOfDay(new Date()), 15)),
  project: undefined,
  mainCat: undefined,
  subCat: undefined,
  worksite: undefined,
  comment: undefined,
  selectedEquipment: undefined,
  orderedBy: undefined,
};

const rentalOrderStateSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setActiveExtraEquipment(state, action: PayloadAction<string[]>) {
      state.activeExtraEquipment = action.payload;
    },
    setFrom(state, action: PayloadAction<Date>) {
      state.from = formatISO(action.payload);
    },
    setTo(state, action: PayloadAction<Date>) {
      state.to = formatISO(action.payload);
    },
    setProject(state, action: PayloadAction<SelectItem|undefined>) {
      state.project = action.payload;
    },
    setMainCat(state, action: PayloadAction<SelectItem|undefined>) {
      state.mainCat = action.payload;
    },
    setSubCat(state, action: PayloadAction<SelectItem|undefined>) {
      state.subCat = action.payload;
    },
    setSubCategory(state, action: PayloadAction<{category: EquipmentCategory | undefined, value: SelectItem|undefined}>) {
      const newMainCat = action.payload.category;
      if (!state.mainCat) {
        if (newMainCat) state.mainCat = ({ id: newMainCat.name, label: newMainCat.name });
      }
      state.subCat = action.payload.value;
    },
    setMainCategory(state, action: PayloadAction<{category: EquipmentCategory | undefined, value: SelectItem|undefined}>) {
      const newCat = action.payload.category;
      if (newCat?.subCategories.length === 1) {
        const newSubCat = newCat.subCategories[0];
        state.subCat = ({ id: newSubCat, label: newSubCat });
      } else if (state.subCat && !newCat?.subCategories.some((c) => c === state.subCat?.id)) {
        state.subCat = undefined;
      }
      state.mainCat = action.payload.value;
    },
    setWorksite(state, action: PayloadAction<string|undefined>) {
      state.worksite = action.payload;
    },
    setComment(state, action: PayloadAction<string|undefined>) {
      state.comment = action.payload;
    },
    setSelectedEquipment(state, action: PayloadAction<SelectItem|undefined>) {
      state.selectedEquipment = action.payload;
    },
    setOrderedBy(state, action: PayloadAction<number|undefined>) {
      state.orderedBy = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const useIsFormValid = () => useSelector((s) => {
  const {
    from,
    to,
    project,
    selectedEquipment,
    mainCat,
    subCat,
    orderedBy,
  } = s.rentalOrderState;
  const periodValid = isBefore(new Date(from), new Date(to));
  if (!periodValid) return false;
  if (!project) return false;
  if (!orderedBy) return false;
  if (!selectedEquipment && !mainCat) return false;
  if (!selectedEquipment && !subCat) return false;
  return true;
});

export const {
  setActiveExtraEquipment,
  setFrom,
  setTo,
  setProject,
  setMainCat,
  setSubCat,
  setMainCategory,
  setSubCategory,
  setWorksite,
  setComment,
  setSelectedEquipment,
  setOrderedBy,
  reset,
} = rentalOrderStateSlice.actions;

export default rentalOrderStateSlice.reducer;
