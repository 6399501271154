import { ReactNode } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import { Unauthenticated } from '../unauthenticated';
import { config } from '../../../config';
import './style.scss';

export const Page = ({
  className,
  children,
} : {
  className?: string,
  children?: ReactNode,
  }) => (
    <Box className={`page-component ${className}`}>
      <MsalAuthenticationTemplate
        authenticationRequest={{ scopes: [config.SCOPE] }}
        interactionType={InteractionType.Redirect}
        errorComponent={Unauthenticated}
      >
        {children}
      </MsalAuthenticationTemplate>
    </Box>
);

export const PageUnauthenticated = ({
  className,
  children,
} : {
  className?: string,
  children?: ReactNode,
  }) => (
    <Box className={`page-component ${className}`}>
      {children}
    </Box>
);
