import { MoveEquipmentAssignment } from '../types/api/moveEquipmentAssignment';
import { EquipmentAssignment } from '../types/equipmentAssignment';

export const convertEquipmentAssignment2MoveEquipmentAssignment = (data: EquipmentAssignment): MoveEquipmentAssignment|null => {
  const {
    orderer,
    equipment,
    from,
    to,
    project,
    comment,
    extraEquipment,
    handInDate,
    handOutDate,
    worksite,
  } = data;
  if (!orderer?.employeeNumber) return null;
  return ({
    equipmentInternalNumber: equipment.internalNumber,
    from,
    to,
    ordererEmployeeNumber: orderer.employeeNumber,
    projectId: project.id,
    comment,
    confirmDelivery: undefined,
    extraEquipmentIds: extraEquipment?.map((e) => e.internalNumber),
    handInDate,
    handOutDate,
    worksite,
  });
};
