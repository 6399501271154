import React, { FC } from 'react';
import {
  ButtonProps,
  CircularProgress,
  Button as MuiButton,
} from '@mui/material';

export const Button: FC<ButtonProps & { loading?: boolean }> = (props) => {
  const {
    loading = false,
    variant,
    color,
  } = props;
  const p = { ...props };
  delete p.loading;

  if (loading) {
    p.children = <CircularProgress size={24} color={variant === 'contained' ? 'secondary' : color} />;
    p.onClick = () => null;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton {...p} />
  );
};
