import {
  format as baseFormat,
  formatISO,
  getUnixTime,
  isWithinInterval,
  startOfDay,
  getWeek as baseGetWeek,
  startOfWeek as baseStartOfWeek,
  endOfWeek as baseEndOfWeek,
  isAfter,
  addMinutes,
  addDays,
  isSameDay,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { DateTime } from '../types/util/dateTime';

export const format = (d: Date|DateTime, f: string) => (
  baseFormat(typeof d === 'string' ? new Date(d) : d, f, { locale: nb })
);

export const getWeek = (d: Date) => (baseGetWeek(d, { locale: nb }));

export const startOfWeek = (d: Date) => (baseStartOfWeek(d, { locale: nb }));

export const endOfWeek = (d: Date) => (baseEndOfWeek(d, { locale: nb }));

export const minutesFromMidnight = (time: Date) => (
  Math.round((getUnixTime(time) - getUnixTime(startOfDay(time))) / 60)
);

export const dateWithoutTimezone = (time: Date) => (
  `${formatISO(time, { representation: 'date' })}T00:00:00Z`
);

export const currentlyWithinPeriod = (start: Date, end: Date, now: Date = new Date()) => (
  isWithinInterval(now, { start, end })
);

export function formatDate(date: Date, formatStr = 'PP') {
  return format(date, formatStr);
}

/**
 * Ensures the given date is after the reference date
 * @param date Date to alter
 * @param refDate Date to compare to
 */
export const ensureAfter = (date: Date, refDate: Date, dayMode: boolean = false): Date => {
  // If the date is after the reference date it's fine
  if (isAfter(date, refDate)) return date;

  if (dayMode) {
    return addDays(refDate, 1);
  }

  // Get the time from each date
  const mins = minutesFromMidnight(date);
  const refMins = minutesFromMidnight(refDate);

  // If time is after reference time, return ref date with time
  if (mins > refMins) {
    return addMinutes(startOfDay(refDate), mins);
  }

  // Time is also before reference date, return reference date + 1 hour
  return addMinutes(refDate, 60);
};

/**
 * Formats a DateTime string
 *
 * @param date Date as DateTime string
 * @param f Format string
 * @returns Formatted string
 */
export const timestringFormat = (date: DateTime, f: string = 'dd.MM.yyyy HH:mm') => (
  format(new Date(date), f)
);

/**
 * Formats two dates as either
 * `date + from time => to time`
 * or
 * `from date + time => to date + time`
 * depending on if they're on the same day
 *
 * @param from Date or DateTime to start the period
 * @param to Date or DateTime to end the period
 * @returns Formatted string
 */
export const autoFormat = (from: Date|DateTime, to: Date|DateTime) => {
  const f = typeof from === 'string' ? new Date(from) : from;
  const t = typeof to === 'string' ? new Date(to) : to;
  let fm = "dd.MM.yyyy 'kl.' HH:mm";
  let preDate = '';
  if (isSameDay(f, t)) {
    fm = 'HH:mm';
    preDate = 'dd.MM.yyyy';
  }
  return `${preDate ? `${format(f, preDate)} kl. ` : ''}${format(f, fm)} til ${format(t, fm)}`;
};

export const formatISODate = (d: Date) => (
  formatISO(startOfDay(d), { representation: 'date' })
);
