import { ConfigurationSettings, createConfig } from '.';
import { getEnvironmentName } from '../../localEnvironment';
import { EnvironmentName } from '../types/enums/environmentName';

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3003',
  BACKEND_URL: 'https://localhost:44308',
  CLIENT_ID: '11770016-7ecd-4b50-8e59-36279dea090d',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://kind-hill-02fda0b03.2.azurestaticapps.net',
  BACKEND_URL: 'https://internutleie-backend-utv.azurewebsites.net',
  CLIENT_ID: '11770016-7ecd-4b50-8e59-36279dea090d',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://internutleie-test.tsmaskin.no/',
  BACKEND_URL: 'https://internutleie-test-backend.azurewebsites.net',
  CLIENT_ID: '3c7aa3ca-3ac8-4740-94a7-422d9554cb38',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://internutleie.tsmaskin.no/',
  BACKEND_URL: 'https://internutleie-prod-backend.azurewebsites.net',
  CLIENT_ID: 'c45cc27e-5610-4758-841e-56715190d871',
};

export const getEnvironment = (
  envName: EnvironmentName,
): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
