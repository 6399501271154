import { Button } from '@mui/material';
import React, { FC } from 'react';
import './style.scss';

export const Unauthenticated: FC = () => (
  <div className="unauthenticated-component">
    <h1>Du er ikke logget inn</h1>
    <Button onClick={() => {
      sessionStorage.clear();
      window.location.replace('/');
    }}
    >
      Log inn
    </Button>
  </div>
);
