import React, {
  FC,
  ReactElement,
  MouseEventHandler,
  useState,
  cloneElement,
} from 'react';
import {
  Box,
  Button,
  Popover,
  Typography,
} from '@mui/material';

/**
 * Shows a pop menu with a button.
 * Children are passed a closemodal function as a prop that closes the modal
 */
export const PopMenu: FC<{
  /** Label for popup */
  label?: string,
  /** Button displayed on page */
  button?: ReactElement,
  /** Side that popup should appear on */
  side?: 'left' | 'right' | 'center',
  children: ReactElement|ReactElement[],
  /** Called when pop menu closes */
  onClose?: () => void,
  /** Called when pop menu opens */
  onOpen?: () => void,
  width?: number | string,
  stopPropagation?: boolean
}> = ({
  label,
  button = <Button>Open</Button>,
  side = 'right',
  children,
  onClose = () => null,
  onOpen = () => null,
  width = 400,
  stopPropagation = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement|null>(null);

  const open: MouseEventHandler<HTMLSpanElement> = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const close = () => {
    setAnchorEl(null);
    onClose();
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {button && cloneElement(button, { onClickCapture: open })}

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: side,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: side,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
      >
        <Box boxSizing="content-box" padding={1.5} width={width}>
          {label
        && (
        <Typography variant="overline" component="div" align="center" sx={{ fontSize: 15 }}>
          {label}
        </Typography>
        )}
          {children}
        </Box>
      </Popover>
    </>
  );
};
