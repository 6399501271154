/**
 * Convert a object to a map of same type
 * @param obj Object to convert
 * @returns Map of object keys to object values
 */
export const objectToMap = <T, >(obj: Record<string, T>): Map<string, T> => {
  const keys = Object.keys(obj);
  const map = new Map();
  for (let i = 0; i < keys.length; i += 1) {
    map.set(keys[i], obj[keys[i]]);
  }
  return map;
};

/**
 * Creates url params from map
 * @param map Map of type <string, string>
 * @returns String including '?' with params
 */
export const mapToParams = (map: Map<string, string>): string => {
  if (map.size <= 0) return '';
  const params: string[] = [];
  map.forEach((v, k) => params.push(`${k}=${v}`));
  return `?${params.join('&')}`;
};
