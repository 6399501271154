import {
  ConfigurationSettings,
  createConfig,
} from '.';
import { getEnvironmentName } from '../../localEnvironment';
import { EnvironmentName } from '../types/enums/environmentName';

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3001',
  BACKEND_URL: 'https://localhost:44306/',
  CLIENT_ID: '988d52ed-02c7-42f9-830e-fb77cc5eac52',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://black-mushroom-096278803.1.azurestaticapps.net',
  BACKEND_URL: 'https://mannskapsplanlegger-backend-utv.azurewebsites.net/',
  CLIENT_ID: '988d52ed-02c7-42f9-830e-fb77cc5eac52',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://mannskap-test.tsmaskin.no',
  BACKEND_URL: 'https://mannskapsplanlegger-test-backend.azurewebsites.net/',
  CLIENT_ID: 'b1bad8b5-d003-4c73-a937-c3a9672a7ac1',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://mannskap.tsmaskin.no',
  BACKEND_URL: 'https://mannskapsplanlegger-prod-backend.azurewebsites.net/',
  CLIENT_ID: '87e9f4a2-67e5-4753-a065-b8976fb0a42a',
};

export const getEnvironment = (envName: EnvironmentName): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
