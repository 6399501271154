import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '../logic/functions';

/**
 * Gets the current URL query parameters, and provides functions to change or
 * remove individual URL parameters
 */
export const useUrl = (): [
  Record<string, string>,
  (url: string, params: Record<string | number, string | number | null>, replace?: boolean) => void,
  (url: string, params: number[] | string[]) => void,
] => {
  const navigate = useNavigate();
  const query = useQuery();

  const change = (url: string, params: Record<string | number, string | number | null>, replace: boolean = true) => {
    const urlParams = new URLSearchParams();
    const parameters = {
      ...query,
      ...params,
    };

    const keys = Object.keys(parameters);
    keys.forEach((k) => {
      if (parameters[k] !== null) {
        urlParams.set(`${k}`, `${parameters[k]}`);
      }
    });

    const paramString = urlParams.toString();
    navigate(paramString ? `${url}?${paramString}` : url, { replace });
  };

  const remove = (url: string, params: number[] | string[]) => {
    const urlParams = new URLSearchParams();

    const keys = Object.keys(query);
    keys.forEach((k) => {
      if (!params.some((p) => `${p}` === k)) {
        urlParams.set(`${k}`, `${query[k]}`);
      }
    });

    const paramString = urlParams.toString();
    navigate(paramString ? `${url}?${paramString}` : url, { replace: true });
  };

  return [query, change, remove];
};

/** Functions like useUrl but the URL is maintained */
export const useSameUrl = (): [
  Record<string, string>,
  (params: Record<string | number, string | number | null>, replace?: boolean) => void,
  (params: number[] | string[]) => void,
] => {
  // addwa
  const location = useLocation();
  const path = location.pathname;
  const [query, change, remove] = useUrl();
  return [
    query,
    (params, replace) => change(path, params, replace),
    (params) => remove(path, params),
  ];
};

export const useStateUrl = <T extends string|undefined>(
  name: string, defaultValue?: T,
  removeOnDefault: boolean = false,
): [T|undefined, (v: T) => void] => {
  const [v, c, d] = useSameUrl();

  const changeValue = (newVal: T) => {
    if (removeOnDefault && newVal === defaultValue) d([name]);
    else if (newVal) c({ [name]: newVal });
    else d([name]);
  };

  return [v[name] as T|undefined || defaultValue, changeValue];
};
