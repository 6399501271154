import React, {
  FC,
  ReactElement,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { Menu, Box, Typography } from '@mui/material';

export const Modal: FC<{
  label?: string,
  closeButton?: ReactElement,
  acceptButton?: ReactElement,
  renderButton?: ReactNode,
  currentDate?: string,
  side?: 'left' | 'right' | 'center',
  onOpen?: (el: HTMLSpanElement) => void,
  onClose?: () => void,
  disabled?: boolean,
  children: ReactNode,
  anchor?: HTMLSpanElement|null,

}> = ({
  label,
  closeButton,
  acceptButton,
  renderButton,
  side = 'right',
  currentDate = '',
  onOpen = () => null,
  onClose = () => null,
  disabled = false,
  children,
  anchor = null,

}) => {
  const open: MouseEventHandler<HTMLSpanElement> = (event) => {
    if (!disabled) {
      onOpen(event.currentTarget);
    }
  };

  const isOpen = Boolean(anchor);

  return (
    <>
      <Box component="span" onClick={open}>
        {renderButton}
      </Box>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={onClose}
        onClick={() => closeButton === undefined && onClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: 1,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              // Calculate offset based on button component width
              right: side === 'right' ? Math.max(8, anchor?.offsetWidth || 40) / 4 : 'unset',
              left: side === 'left' ? Math.max(8, anchor?.offsetWidth || 32) / 4 : 'unset',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: side, vertical: 'top' }}
        anchorOrigin={{ horizontal: side, vertical: 'bottom' }}
      >
        {label
        && (
        <Typography variant="overline" component="div" align="center" sx={{ fontSize: 15 }}>
          {label}
        </Typography>
        )}
        {children}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        >
          <Typography sx={{
            flex: 1,
            fontWeight: 'bold',
            fontSize: 18,
            paddingLeft: 1,
          }}
          >{currentDate}
          </Typography>
          {closeButton && React.cloneElement(closeButton, { onClick: onClose })}
          {acceptButton && React.cloneElement(acceptButton, { onClick: onClose })}
        </Box>
      </Menu>
    </>
  );
};
