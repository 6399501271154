import {
  Autocomplete,
  Skeleton,
  TextField,
} from '@mui/material';
import { SelectItem } from '../../types/util/selectItem';
import './style.scss';
import { SearchSelectFilterFunction } from '../../types/util/searchSelectFilterFunction';

/** Input method for selecting a single item in a list */
export const SearchSelect = <T extends string|number = string|number>({
  label = 'Søk',
  errorLabel = 'Dette feltet er påkrevd',
  value = null,
  onChange = () => null,
  onClose = () => null,
  size = 'medium',
  children = [],
  required = false,
  forceError = false,
  disabled = false,
  loading = false,
  disableClearable = false,
  filterOptions,
}: {
  label?: string,
  errorLabel?: string,
  value?: SelectItem<T>|null,
  onChange?: (newValue: SelectItem<T>|undefined) => void,
  onClose?: () => void,
  size?: 'medium' | 'small',
  children?: SelectItem<T>[],
  required?: boolean,
  forceError?: boolean,
  disabled?: boolean,
  loading?: boolean,
  disableClearable?: boolean,
  filterOptions?: SearchSelectFilterFunction<T>,
}) => {
  if (loading) {
    return (
      <Skeleton
        width="100%"
        height={`${size === 'medium' ? 56 : 40}px`}
        variant="rectangular"
      />
    );
  }

  return (
    <Autocomplete
      className="search-select-component"
      disableClearable={disableClearable}
      value={value}
      disabled={disabled}
      isOptionEqualToValue={(a: SelectItem, b: SelectItem) => `${a.id}` === `${b.id}`}
      clearOnBlur
      clearOnEscape
      openOnFocus
      filterOptions={filterOptions}
      getOptionDisabled={(opt) => opt.disabled === true}
      onChange={(e, newValue) => {
        onChange(newValue || undefined);
      }}
      onClose={onClose}
      options={children}
      fullWidth
      getOptionLabel={(option) => option?.label}
      renderInput={(params) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          helperText={(required && !value) || forceError ? errorLabel : null}
          error={(required && !value) || forceError}
        />
      )}
      size={size}
    />
  );
};

/**
 * Same as a search select, but uses a string or number for value and onChange
 * directly instead of using SelectItems
 */
export const SearchSelectDirect = <T extends string|number>({
  label = 'Søk',
  errorLabel = 'Dette feltet er påkrevd',
  value,
  notFoundLabel,
  onChange = () => null,
  onClose = () => null,
  size = 'medium',
  children = [],
  required = false,
  forceError = false,
  disabled = false,
  loading = false,
  disableClearable = false,
  filterOptions,
}: {
  label?: string,
  errorLabel?: string,
  value?: T|null,
  notFoundLabel?: string|undefined,
  onChange?: (newValue: T|undefined) => void,
  onClose?: () => void,
  size?: 'medium' | 'small',
  children?: SelectItem<T>[],
  required?: boolean,
  forceError?: boolean,
  disabled?: boolean,
  loading?: boolean,
  disableClearable?: boolean,
  filterOptions?: SearchSelectFilterFunction<T>,
}) => {
  const change = (v: SelectItem<T>|undefined) => onChange(v?.id);
  const val = value !== null && value !== undefined
    ? children.find((c) => c.id === value) || { id: value, label: notFoundLabel ?? `${value}` }
    : null;
  return (
    <SearchSelect
      label={label}
      errorLabel={errorLabel}
      value={val}
      onChange={change}
      onClose={onClose}
      size={size}
      required={required}
      forceError={forceError}
      disabled={disabled}
      loading={loading}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
    >
      {children}
    </SearchSelect>
  );
};
