export interface EquipmentEditRules {
  // can edit
  editTime: boolean,
  editProject: boolean,
  editEquipment: boolean,
  editSubCat: boolean,
  editWorksite: boolean,
  editComment: boolean,
  editHandOut: boolean,
  editHandIn: boolean,
  editOrderComment: boolean,
  editInternalComment: boolean,
  editOrderer: boolean;

  // show jsx/component
  seeEquipment: boolean,
  seeMainCat: boolean,
  seeSubCat: boolean,
  seeComment: boolean,
  seeOrderComment: boolean,
  seeInternalComment: boolean,
  seeLastEditBy: boolean,
  seeApprovedBy: boolean,
  seeOrderCard: boolean,

  // buttons
  seeAssignEquipmentButton: boolean,
  seeApproveEquipmentButton: boolean,
  seeCancelButton: boolean,
  seeSaveButton: boolean,

  // text
  closeButtonText: string,
  cancelButtonText: string,
}

export const equipmentEditRulesDefaults: EquipmentEditRules = {
// can edit
  editTime: false,
  editProject: false,
  editEquipment: false,
  editSubCat: false,
  editWorksite: false,
  editComment: false,
  editHandOut: false,
  editHandIn: false,
  editOrderComment: false,
  editInternalComment: false,
  editOrderer: false,
  // show jsx/component
  seeMainCat: false,
  seeSubCat: false,
  seeEquipment: false,
  seeComment: false,
  seeOrderComment: false,
  seeInternalComment: false,
  seeLastEditBy: false,
  seeApprovedBy: false,
  seeOrderCard: false,

  // buttons
  seeAssignEquipmentButton: false,
  seeApproveEquipmentButton: false,
  seeCancelButton: false,
  seeSaveButton: false,

  // text
  closeButtonText: 'Lukk',
  cancelButtonText: 'Kanseller',
};
