import React, { FC, ReactNode, useState } from 'react';
import { Popover, ButtonProps } from '@mui/material';
import { Button } from '../button';

export const PopConfirm: FC<{
  buttonText?: string | ReactNode,
  buttonColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning',
  buttonVariant?: 'contained' | 'outlined' | 'text',
  title?: string,
  content?: string | ReactNode,
  loading?: boolean,
  cancelText?: string,
  confirmText?: string,
  confirmColor?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning',
  onConfirm?: () => void,
  buttonProps?: ButtonProps,
}> = ({
  buttonText = 'Slett',
  buttonColor = 'error',
  buttonVariant = 'contained',
  title = 'Er du sikker?',
  content,
  cancelText = 'Avbryt',
  confirmText = 'Slett',
  confirmColor = 'error',
  onConfirm = () => null,
  buttonProps = {},
  loading = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirm = () => {
    setAnchorEl(null);
    onConfirm();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popid = open ? 'popover' : undefined;
  return (
    <>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        onClick={handleClick}
        loading={loading}
      >
        {buttonText}
      </Button>
      <Popover
        id={popid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={8}
        transitionDuration="auto"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <div className="m10">
          <div className="ml5 mb10">
            <span className="bold-text font-size-17">{title}</span>
            <div className="mt5">
              <span className="font-size-15 italic">
                {content || (
                <>
                  Vil du slette dette objektet?<br />
                  Det vil ikke være mulig å angre slettingen
                </>
                )}
              </span>
            </div>
          </div>
          <div className="text-align-end">
            <Button variant="outlined" onClick={handleClose} sx={{ m: 0.75 }}>{cancelText}</Button>
            <Button
              sx={{ m: 0.75 }}
              variant="contained"
              color={confirmColor}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
