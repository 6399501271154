import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { format } from '../../logic/dates';
import { DateTime } from '../../types/util/dateTime';

export type LastEditData = {
  createdByName?: string;
  createdTimestamp?: DateTime;
  lastEditByName?: string;
  lastEditedByName?: string;
  lastEditTime?: DateTime;
}

export const LastEditInfo = ({ data, horizontal = false, darkMode = false }: {data: LastEditData, horizontal?: boolean, darkMode?: boolean}) => {
  const {
    createdTimestamp,
    createdByName,
    lastEditedByName,
    lastEditByName,
    lastEditTime,
  } = data;
  const sx: SxProps<Theme> = {
    fontSize: 11,
    color: darkMode ? 'white' : '#666',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };
  const lastEditName = lastEditByName || lastEditedByName;

  const edited = lastEditName && lastEditTime && (
    <Typography sx={sx}>
      Sist redigert {format(lastEditTime, "yyyy.MM.dd 'kl.' HH:mm")} av {lastEditName}
    </Typography>
  );
  const created = createdByName && createdTimestamp && (
    <Typography sx={sx}>
      Oprettet {format(createdTimestamp, "yyyy.MM.dd 'kl.' HH:mm")} av {createdByName}
    </Typography>
  );
  return (
    <Box sx={horizontal ? { display: 'flex', gap: 4 } : { display: 'flex', flexDirection: 'column' }}>
      {edited}
      {created}
    </Box>
  );
};
