import { urlCombine } from '../logic/functions';
import { Configuration } from '../types/configuration';

export type ConfigurationSettings = {
  FRONTEND_URL: string;
  BACKEND_URL: string;
  CLIENT_ID: string;
}

// Having everything here provides autocomplete
export const common: Configuration = {
  BACKEND_URL: '', // Backend URL
  CLIENT_ID: '', // Frontend Application/Client ID
  API_URL: '', // Actual API URL
  HUB_URL: '', // Actual hub URL
  SCOPE: '', // Backend Appication/Client ID - automaticly set from client_id
  API_PATH: '/api/v1.0', // API path on backend
  HUB_PATH: '/hubs/v1.0', // hub path on backend
  AUTHORITY: 'https://login.microsoftonline.com/68aad708-5e24-4f71-9201-974eac2ecb55', // Frontend Directory/Tenant ID
  FRONTEND_URL: '', // Frontend URL
  TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSS', // Backend time format
};

export const createConfig = (c: ConfigurationSettings): Configuration => ({
  ...common,
  API_URL: urlCombine(`${c.BACKEND_URL}`, `${common.API_PATH}`),
  HUB_URL: urlCombine(`${c.BACKEND_URL}`, `${common.HUB_PATH}`),
  SCOPE: `api://${c.CLIENT_ID}/access`,
  ...c,
});
