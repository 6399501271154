import { areIntervalsOverlapping, format } from 'date-fns';
import { useMemo } from 'react';
import { ensureAfter } from '../logic/dates';
import { SelectItem } from '../types/util/selectItem';
import { EquipmentAssignment } from '../types/equipmentAssignment';

export const createOverlapMessage = (overlappingEvents: EquipmentAssignment[]) => {
  if (overlappingEvents.length !== 0) {
    return (
      overlappingEvents.map((e) => {
        const start = new Date((e.status === 'Approved' && e.handOutDate) ? e.handOutDate : e.from);
        return (
          <span key={e.id} className="event">
            {format(start, 'dd.MM.yyyy')} - {format(ensureAfter(new Date((e.status === 'Approved' && e.handInDate) ? e.handInDate : e.to), start), 'dd.MM.yyyy')} (prosjekt: {e.project.id} - {e.project.projectName})
          </span>
        );
      })
    );
  }
  return null;
};

/** Determine which other assignments are overlapping with the current one */
export const useOverlapMessage = (
  /** List of all assignments */
  allAssignments: EquipmentAssignment[] | undefined,
  /** Active assignment */
  order: EquipmentAssignment | undefined,
  /** Selected equipment */
  equipment: SelectItem<string|number>|undefined,
  /** Currently selected from date */
  from: Date,
  /** Currently selected to date */
  to: Date,
  /** Currently selected hand out date */
  handOutDate: Date|undefined,
  /** Currently selected hand in date */
  handInDate: Date|undefined,
) => {
  const overlapMessage = useMemo(() => {
    if (!equipment || !order || !allAssignments) {
      return null;
    }
    let overlappingEvents: EquipmentAssignment[] = [];
    if (order.type === 'Order') {
      overlappingEvents = allAssignments.filter((a) => a.equipment?.internalNumber === equipment.id
          && areIntervalsOverlapping(
            { start: new Date(a.from), end: new Date(a.to) },
            { start: from, end: to },
          ));
    }
    if (order.type === 'Assignment') {
      const currentStart = (order.status === 'Approved' && handOutDate) ? handOutDate : from;
      overlappingEvents = allAssignments.filter((a) => {
        const comparedStart = new Date((a.status === 'Approved' && a.handOutDate) ? a.handOutDate : a.from);
        return (
          order.id !== a.id && a.equipment?.internalNumber === equipment.id
          && areIntervalsOverlapping(
            {
              start: comparedStart,
              end: ensureAfter(new Date((a.status === 'Approved' && a.handInDate) ? a.handInDate : a.to), comparedStart),
            },
            {
              start: currentStart,
              end: ensureAfter((order.status === 'Approved' && handInDate) ? handInDate : to, currentStart),
            },
          )
        );
      });
    }

    return createOverlapMessage(overlappingEvents);
  }, [from, to, handOutDate, handInDate, allAssignments, order, equipment]);
  return overlapMessage;
};
