import CommentIcon from '@mui/icons-material/Comment';

export const EventCommentIcon = () => (
  <CommentIcon
    sx={{
      alignSelf: 'center',
      fontSize: '14px',
      justifySelf: 'center',
      transform: 'translateY(1px)',
    }}
    fontSize="small"
  />
);
