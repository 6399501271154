import { ConfigurationSettings, createConfig } from '.';
import { getEnvironmentName } from '../../localEnvironment';
import { EnvironmentName } from '../types/enums/environmentName';

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3000',
  BACKEND_URL: 'https://localhost:44305/',
  CLIENT_ID: '50fb12d5-47e9-443a-a279-9543b962acd4',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://black-moss-03e038c03.azurestaticapps.net',
  BACKEND_URL: 'https://planlegger-backend-utv.azurewebsites.net',
  CLIENT_ID: '50fb12d5-47e9-443a-a279-9543b962acd4',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://transport-test.tsmaskin.no/',
  BACKEND_URL: 'https://planlegger-backend-test.azurewebsites.net',
  CLIENT_ID: '08cbc0e5-1173-442e-ba91-700547c6045c',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://transport.tsmaskin.no/',
  BACKEND_URL: 'https://planlegger.azurewebsites.net',
  CLIENT_ID: '91858db9-3634-47f6-91f3-679ceaa22439',
};

export const getEnvironment = (envName: EnvironmentName): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
