import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { msalInstance } from './msal';
import { Configuration } from './types/configuration';

/**
 * Sleeps for a given time
 *
 * @param millis Milliseconds to sleep for
 * @returns Promise which resolves after the delay
 */
const sleep = async (t: number) => new Promise((r) => { setTimeout(r, t); });

export async function getAccessToken(config: Configuration) {
  let account = null;
  let tries = 5; // Try to get the account 5 times - fixes cold login
  while (!account && tries > 0) {
    tries -= 1;
    account = msalInstance.getActiveAccount();
    if (!account) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(500);
    }
  }
  if (!account) {
    throw new Error('No account found');
  }
  const request: SilentRequest = {
    account,
    scopes: [config.SCOPE],
  };
  return msalInstance.acquireTokenSilent(request)
    .then((tokenResponse) => tokenResponse.accessToken).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(request);
      }
      return null;
    });
}

export async function addAuthHeaders(config: Configuration, headers: Headers = new Headers()) {
  const token = await getAccessToken(config);
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
}

export function apiPath(config: Configuration, path: string) {
  return `${config.API_URL.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
}

export async function callApi(config: Configuration, path: string, method: string = 'GET') {
  const headers = await addAuthHeaders(config);
  const url = apiPath(config, path);
  return fetch(url, { method, headers });
}

export async function get(config: Configuration, path: string) {
  const response = await callApi(config, path);
  if (response.status === 200) {
    const body = await response.text();
    return body;
  }
  throw new Error(`Could not complete request, status code ${response.status}`);
}
