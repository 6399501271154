import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';

export type TabDefinition = {
    id: number|string,
    title: string,
    content: ReactNode,
    default?: boolean,
};

export const Tabs = ({
  tabs,
  tabHeight = 40,
}: {
    tabs: TabDefinition[],
    tabHeight?: number,
}) => {
  const [tab, setTab] = useState<string|number|undefined>(
    tabs.find((t) => t.default)?.id || tabs[0]?.id,
  );
  const tabComponents = useMemo(() => (
    tabs.map((t) => <Tab key={t.id} value={t.id} label={t.title} sx={{ height: tabHeight, minHeight: tabHeight }} />)
  ), [tabs]);

  return (
    <Box>
      <MuiTabs sx={{ minHeight: tabHeight, mb: 4, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} value={tab} onChange={(e, t) => setTab(t)}>
        {tabComponents}
      </MuiTabs>
      {tabs.map((t) => <Box key={t.id} hidden={t.id !== tab}>{t.content}</Box>)}
    </Box>
  );
};
