import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Logout, Public } from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { logOut, msalInstance } from '../../msal';
import { useUrl } from '../../hooks/params';
import { useGetVersionQuery } from '../../redux/version';
import { getEnvironmentName } from '../../../localEnvironment';
import { stopPropagation } from '../../logic/functions';
import { PageName } from '../../types/util/pageName';
import { useCanAccessOperationStatus } from '../../hooks/authorization';
import { useRoles } from '../../hooks/useRoles';
import { config as workerConfig } from '../../config/worker';
import { config as machineConfig } from '../../config/machine';
import { config as rentalConfig } from '../../config/rental';

const tabSx = { color: '#dedede', fontWeight: 'bold', fontSize: '16px' };

export const Header: FC<{
  page?: PageName,
  userIconColor?: string,
  children?: ReactNode,
  initials?: string,
  extraMenuItems?: ReactNode,
  middleContent?: ReactNode,
  disableMap?: boolean,
  noServerVersion?: boolean,
}> = ({
  page,
  userIconColor,
  children,
  initials,
  extraMenuItems = null,
  middleContent = null,
  disableMap = false,
  noServerVersion = false,
}) => {
  const account: AccountInfo|null = msalInstance.getActiveAccount();

  const isAuthenticated = useIsAuthenticated();
  const [, change] = useUrl();

  const actualInitials = useMemo(() => {
    if (initials !== undefined) {
      return initials;
    }

    let nameArray: string[]|null = null;
    if (account?.name) {
      nameArray = account.name.split(' ');
    }
    const firstName = nameArray ? nameArray[0] : null;
    const lastName = nameArray ? nameArray[nameArray.length - 1] : null;
    if (!firstName || !lastName) { // can not get username
      return 'N/A';
    }
    return (firstName.charAt(0) + lastName.charAt(0));
  }, [msalInstance, initials]);

  const { data: serverVersion } = useGetVersionQuery(undefined, { skip: noServerVersion });

  const env = getEnvironmentName(document.baseURI);

  const workerAccess = useRoles(workerConfig).roles?.length > 0;
  const machineAccess = useRoles(machineConfig).roles?.length > 0;
  const rentalAccess = useRoles(rentalConfig).roles?.length > 0;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkLogIn = () => isAuthenticated;

  const displayLogIn = () => (
    <div>
      <Tooltip
        title={<Typography fontSize={14}>Profil</Typography>}
        placement="bottom"
        enterDelay={300}
        enterNextDelay={300}
        arrow
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="profile"
          onClick={handleClick}
        >
          <Avatar sx={{ width: 36, height: 36, backgroundColor: userIconColor }}>{actualInitials}</Avatar>
        </IconButton>
      </Tooltip>
    </div>
  );
  // TODO: change placeholder
  const pageHandler = (_: any, v: any) => {
    if (v === 'worker') return change('/mannskap', { placeholder: null });
    if (v === 'project') return change('/prosjekt', { placeholder: null });
    if (v === 'machine') return change('/maskin', { placeholder: null });
    if (v === 'rental') return change('/internutleie', { placeholder: null });
    if (v === 'status') return change('/status', { placeholder: null });
    return null;
  };

  const { canAccessOperationStatus } = useCanAccessOperationStatus();

  const mapUrl = useMemo(() => {
    if (env === 'prod') return 'https://kart.tsmaskin.no';
    return 'https://app-tsmaskin-map-web-test.azurewebsites.net';
  }, [env]);

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flex: 1, display: 'flex', gap: 5 }}>
            {children || (
            <Tabs value={page} onChange={pageHandler} indicatorColor="secondary" textColor="secondary" aria-label="basic tabs example">
              {workerAccess && <Tab label="Prosjekt" value="project" sx={tabSx} />}
              {workerAccess && <Tab label="Mannskap" value="worker" sx={tabSx} />}
              {machineAccess && <Tab label="Maskin" value="machine" sx={tabSx} />}
              {rentalAccess && <Tab label="Internutleie" value="rental" sx={tabSx} />}
              {canAccessOperationStatus && <Tab label="Driftstatus" value="status" sx={tabSx} />}
            </Tabs>
            )}
            {middleContent}
          </Box>
          <Box display="flex" gap={1}>
            {!disableMap && (
            <Tooltip
              title={<Typography fontSize={14}>Kart</Typography>}
              placement="bottom"
              enterDelay={300}
              enterNextDelay={300}
              arrow
            >
              <IconButton
                color="secondary"
                href={mapUrl}
                target="_blank"
                sx={{ padding: 0, marginRight: 1 }}
              >
                <Public sx={{ height: 42, width: 42 }} />
              </IconButton>
            </Tooltip>
            )}
            {checkLogIn() && displayLogIn()}
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar />
          {' '}
          {account?.name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logg ut
        </MenuItem>
        <MenuItem sx={{ userSelect: 'text' }} onClick={stopPropagation} disableRipple disableTouchRipple>
          <Typography variant="caption">{process.env.REACT_APP_VERSION} {serverVersion && (<>/ {serverVersion.version}</>)} ({env !== 'prod' ? env : ''})</Typography>
        </MenuItem>
        {extraMenuItems}
      </Menu>
    </>
  );
};
